<template>
  <div class="event-marketing-setting">
    <p class="card-title"><span class="font-bold text-danger">*</span>{{ displayText.blockTitle}}</p>
    <BaseElForm
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <!-- 發送時間 -->
      <!-- <BaseElFormItem label="發送時間" prop="sendDateSetting" required> -->
      <BaseElFormItem prop="sendDateSetting" required>
        <BaseElRadioGroup
          v-model="formData.sendDateSetting"
          class="flex flex-col"
          style="gap: 20px"
          @change="resetTime"
        >
          <div>
            <BaseElRadio label="immediately">
              <div class="inline-flex items-center" style="gap: 4px">
                <span>{{ displayText.immediately.label }}</span>
                <!-- <span v-show="sourceType === 'memberExternalTransactionCompleted'">(依據POS端同步銷售資料後時間發送：約凌晨6:00)</span> -->
                <el-tooltip
                  placement="right"
                  class="condition-help"
                >
                  <div slot="content" class="w-[380px]">
                    {{ displayText.immediately.tooltip }}
                    <ul class="list-disc pl-[16px]" v-html="displayText.immediately.tooltipList" />
                  </div>
                  <span class="relative" style="top: 2px">
                    <span v-show="showControl.memberExternalTransactionCompletedTooltip" class="material-icons">help_outline</span>
                  </span>
                </el-tooltip>
              </div>
            </BaseElRadio>
          </div>
          <div v-if="showControl.specifySendDay">
            <BaseElRadio label="specify">
              {{ displayText.specify.label }}
              <div
                class="flex"
                style="margin-left: 25px; margin-top: 8px; gap: 20px"
              >
                <div class="flex items-center" style="gap: 20px">
                  <span>{{ displayText.specify.day }}</span>
                  <BaseElFormItem prop="part.specifySendDay">
                    <BaseElInput
                      v-model="formData.part.specifySendDay"
                      :disabled="formData.sendDateSetting !== 'specify'"
                      style="width: 100px"
                      :placeholder="displayText.input.placeholder"
                    />
                  </BaseElFormItem>
                  <span>{{ displayText.specify.dayAfter }}</span>
                </div>

                <BaseElFormItem :show-message="false" prop="specifySendTime">
                  <div class="flex items-center" style="gap: 20px">
                    <el-time-picker
                      v-model="formData.part.specifySendTime"
                      style="width: 135px"
                      :disabled="formData.sendDateSetting !== 'specify'"
                      disabled-seconds
                      format="HH:mm"
                      :picker-options="timeOptions"
                      :placeholder="displayText.specify.timePlaceholder"
                    />
                    <span>{{ displayText.specify.send }}</span>
                  </div>
                </BaseElFormItem>
              </div>
            </BaseElRadio>
          </div>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  nextTick,
  computed,
  watch,
} from 'vue'
import { get, set } from 'lodash'
import dayjs from '@/lib/dayjs'
import {
  noEmptyRules,
  minRules,
} from '@/validation'
import { onFormRulesChangeClearValidate } from '@/use/useForm'

export default defineComponent({
  name: 'EventMarketingTimeSetting',
  props: [
    'FormsContext',
    'eventData',
    'sourceType',
  ],
  setup (props) {
    const formRef = ref(null)
    const formData = reactive({
      sendDateSetting: null,
      part: {
        specifySendDay: null,
        specifySendTime: null,
      },
    })
    const displayText = computed(() => {
      const data = {
        blockTitle: '發送時間設定',
        immediately: {
          label: '當下發送',
          tooltip: '說明：第三方消費紀錄認列時間，視各分店所串接的POS系統而定。',
          tooltipList: `<li>微碧POS- 約每10分鐘認列一次</li>
                      <li>瑞乘POS- 隔日凌晨約4:00撈取前一日紀錄</li>
                      <li>OPENTIX POS - 約每小時33分認列一次</li>`,
        },
        specify: {
          label: '指定時間',
          day: '隔',
          dayAfter: '天後的',
          timePlaceholder: '請選擇時間',
          send: '發送',
        },
        input: {
          placeholder: '請輸入',
        }
      }
      return data
    })
    const showControl = computed(() => {
      const controls = {
        memberExternalTransactionCompletedTooltip: props.sourceType === 'memberExternalTransactionCompleted',
        specifySendDay: showSpecifySendDay.value,
      }
      return controls
    })
    const timeOptions = computed(() => {
      if (props.sourceType !== 'memberExternalTransactionCompleted') {
        return {
          selectableRange: '00:00:00 - 23:59:59',
        }
      }
      return {
        selectableRange: '00:00:00 - 23:59:59',
      }
    })

    const formRules = computed(() => {
      const rules = {
        sendDateSetting: [noEmptyRules('請選擇發送時間')],
      }
      if (formData.sendDateSetting === 'specify') {
        rules['part.specifySendDay'] = [
          noEmptyRules('請輸入天數'),
          minRules(1, '指定時間最少須設定1天（隔日），不可輸入0天（當日）'),
        ]
      }

      return rules
    })

    onFormRulesChangeClearValidate(formRef, formRules)

    const showSpecifySendDay = computed(() => {
      const config = ['level', 'birthday']
      return !config.includes(props.sourceType)
    })

    const compactData = computed(() => {
      const data = {
        sendDateSetting: get(formData, 'sendDateSetting'),
      }
      if (data.sendDateSetting === 'specify') {
        const hour = dayjs(formData.part.specifySendTime).format('HH')
        const minute = dayjs(formData.part.specifySendTime).format('mm')
        data.specifySendDay = get(formData.part, 'specifySendDay')
        data.specifySendTime = Number(hour * 60) + Number(minute)
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.eventData
      const { award } = data.config
      set(formData, 'sendDateSetting', award.sendDateSetting)
      set(formData.part, 'specifySendDay', award.specifySendDay)
      set(formData.part, 'specifySendTime', dayjs().startOf('day').add(award.specifySendTime, 'minutes'))
    }

    onMounted(async () => {
      await nextTick()

      if (get(props.eventData, 'id')) syncData()
      props.FormsContext.setFormRef('time', formRef.value)
    })

    watch(formData, () => {
      props.FormsContext.setFormData('time', { ...compactData.value })
    })

    const resetTime = () => {
      formData.part = {
        specifySendDay: null,
        specifySendTime: null,
      }
    }

    return {
      formRef,
      formData,
      formRules,
      resetTime,
      timeOptions,
      compactData,
      displayText,
      showControl,
    }
  },
})
</script>

<style lang="postcss" scoped>
</style>
