<template>
  <div class="message-block">
    <ImageCropper
      v-if="uploadDialog"
      :image="syncModel.Image"
      @close="onCloseCropper"
      @uploaded="getImage"
    />
    <div
      v-if="showControl.closeBtn"
      class="close"
      :class="{ 'isEventMessage': isEventMessage }"
      @click="$emit('close')"
    >
      <SVGIcon
        icon="common-trash"
        fill="var(--gray-60)"
      />
    </div>
    <section class="header flex-between">
      <span class="title">{{ displayText.blockTitle }}</span>
      <div class="icon-btn-container" v-if="showControl.showType">
        <div
          :class="{ active: syncModel.type === 'text' }"
          class="icon"
          style="width: fit-content"
          @click="changeType('text')"
        >
          <MaterialIcon size="20">chat_bubble_outline</MaterialIcon>
        </div>

        <div
          :class="{ active: syncModel.type === 'image' }"
          class="icon"
          @click="changeType('image')"
        >
          <MaterialIcon size="20">crop_original</MaterialIcon>
        </div>
      </div>
    </section>

    <BaseElForm ref="formContentRef" :model="syncModel" :rules="formRules">
      <BaseElFormItem prop="content">
        <BaseElInput
          v-if="showControl.text"
          v-model="syncModel.content"
          v-loading="hintLoading"
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 8 }"
          :placeholder="displayText.input.placeholder"
          :maxlength="maxContent"
          show-word-limit
        />
        <AiHintBlock
          v-if="showControl.aiHint"
          class="mt-[20px]"
          :hintLoading="hintLoading"
          showKeepOriginalContent
          @confirm="handleConfirm"
        />
        <UploadButton
          v-if="showControl.image"
          class="upload"
          :img="syncModel.Image"
          @change="imageChange"
        />
      </BaseElFormItem>
    </BaseElForm>

    <section v-if="showControl.image">
      <div>
        <p class="img-hint-text">{{ displayText.img.hint }}</p>
        <div class="imageAction" v-if="showControl.showAction">
          <p>{{ displayText.img.action.label }}</p>

          <BaseElRadioGroup
            v-model="syncModel.action"
            style="margin: 8px 0;"
            @change="clearActionContent"
          >
            <BaseElRadio :label="undefined">{{ displayText.img.action.none }}</BaseElRadio>
            <BaseElRadio label="uri">{{ displayText.img.action.uri }}</BaseElRadio>
            <BaseElRadio label="message">{{ displayText.img.action.message }}</BaseElRadio>
          </BaseElRadioGroup>

          <BaseElForm ref="formActionRef" :model="syncModel" :rules="formRules">
            <BaseElFormItem
              v-if="showControl.uri"
              class="mb-0"
              prop="actionContent"
            >
              <ElInputWrapper>
                <BaseElInput
                  v-model="syncModel.actionContent"
                  class="w-100"
                  :type="syncModel.action === 'url' ? 'url' : 'text'"
                  :placeholder="displayText.input.text"
                  clearable
                  @clear="clearActionContent"
                />
              </ElInputWrapper>
            </BaseElFormItem>

            <BaseElFormItem
              v-if="showControl.message"
              class="mb-0"
              prop="actionContent"
            >
              <ElInputWrapper>
                <BaseElInput
                  v-model="syncModel.actionContent"
                  class="w-100"
                  :type="syncModel.action === 'url' ? 'url' : 'text'"
                  :placeholder="displayText.input.text"
                  clearable
                  @clear="clearActionContent"
                />
              </ElInputWrapper>
            </BaseElFormItem>
          </BaseElForm>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { noEmptyRules, urlRules } from '@/validation'
import formUtils from '@/utils/form'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { get } from 'lodash'
import { computed, defineComponent, reactive, ref, set, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { hint } from '@/config/hint'
import { useBaseForm } from '@/use/useForm'

export default defineComponent({
  name: 'MessageBlock',
  components: {
    UploadButton,
    ImageCropper,
    AiHintBlock,
    ElInputWrapper,
  },
  // props: ['model', 'closeBtn'],
  props: {
    model: {
      type: Object,
      default: () => ({
        type: 'text',
        content: '',
        action: undefined,
        actionContent: undefined,
        Image: undefined,
      }),
    },
    closeBtn: {
      type: Boolean,
      default: false,
    },
    onlyText: {
      type: Boolean,
      default: false,
    },
    maxContent: {
      type: [String, Number],
      default: '200',
    },
    aiHint: {
      type: Boolean,
      default: false,
    },
    hideAction: {
      type: Boolean,
      default: false,
    },
    isEventMessage: {
      type: Boolean,
      default: false,
    }
  },
  setup (props, { emit }) {
    const { configurationHint, hintLoading } = useAi()
    const syncModel = useVModel(props, 'model', emit)
    const uploadDialog = ref(false)
    const formContentRef = ref(null)
    const formActionRef = ref(null)
    const formRefs = reactive({
      formContentRef,
      formActionRef,
    })
    const { checkForm } = useBaseForm()

    const formRules = computed(() => {
      const rules = {
        content: [noEmptyRules('請輸入內容')],
        actionContent: [noEmptyRules('請輸入內容')],
      }
      if (get(showControl.value, 'uri')) {
        rules.actionContent = [noEmptyRules('請輸入內容'), urlRules()]
      }
      return rules
    })
    const displayText = computed(() => {
      const data = {
        blockTitle: '訊息',
        input: {
          placeholder: '請輸入內容',
          text: '文字',
        },
        img: {
          hint: '圖片最大寬度為 2000 px',
          action: {
            label: '點擊圖片行為',
            none: '無反應',
            uri: '點擊跳轉網址',
            message: '點擊發送文字',
          },
        }
      }
      return data
    })
    const showControl = computed(() => {
      const controls = {
        closeBtn: props.closeBtn,
        showType: !props.onlyText,
        text: get(syncModel.value, 'type') === 'text',
        aiHint: props.aiHint && get(syncModel.value, 'type') === 'text',
        image: get(syncModel.value, 'type') === 'image',
        showAction: !props.hideAction,
        uri: get(syncModel.value, 'action') === 'uri',
        message: get(syncModel.value, 'action') === 'message',
      }
      return controls
    })

    const checkEventMessage = async () => {
      const isContentValid = await checkForm(formContentRef.value);
      let isActionValid = true;
      if(formActionRef.value) {
         isActionValid = await checkForm(formActionRef.value);
      }
      return isContentValid && isActionValid
    };
    const handleConfirm = async (payload) => {
      payload.origin = payload.origin ? { blockText: get(syncModel.value, 'content') } : undefined
      const key = payload.origin ? 'groupPushMessageCorrection.key' : 'groupPushMessage.key'
      const res = await configurationHint(get(hint, key), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      set(syncModel.value, 'content', get(res.hint, 'blockText'))
    }
    const changeType = async (type) => {
      set(syncModel.value, 'type', type)
      set(syncModel.value, 'content', undefined)
      set(syncModel.value, 'action', undefined)
      set(syncModel.value, 'actionContent', undefined)
      await resetForm()
      emit('checked')
      set(syncModel.value, 'Image', undefined)
    }
    const resetForm = async () => {
      if (formContentRef.value) await formUtils.resetForm(formContentRef.value)
    }
    const clearActionContent = () => {
      set(syncModel.value, 'actionContent', undefined)
    }
    const imageChange = (img) => {
      set(syncModel.value, 'Image', img)
      uploadDialog.value = true
    }

    const getImage = (img) => {
      set(syncModel.value, 'Image', img)
      uploadDialog.value = false
      set(syncModel.value, 'content', img.id)
    }
    const onCloseCropper = () => {
      uploadDialog.value = false
      set(syncModel.value, 'Image', null)
    }
    watch(() => get(syncModel.value, 'content'), checkEventMessage)
    watch(() => get(syncModel.value, 'actionContent'), checkEventMessage)

    return {
      syncModel,
      hintLoading,
      handleConfirm,
      displayText,
      showControl,
      formRules,
      uploadDialog,
      imageChange,
      getImage,
      formContentRef,
      formActionRef,
      changeType,
      resetForm,
      formRefs,
      checkEventMessage,
      onCloseCropper,
      clearActionContent,
    }
  },
})
</script>

<style scoped lang="scss">
section {
  margin: 0;
}

.img-hint-text {
  @apply text-sm text-gray-60 my-[16px] leading-[14.4px] font-medium;
}

.message-block {
  @apply relative w-[440px] p-[20px] bg-white rounded-sm;

  .header {
    @apply mb-[20px];
    .icon-btn-container {
      @apply flex items-center gap-[15px];
      .icon {
        @apply grid place-content-center cursor-pointer text-[20px] w-[24px] h-[24px];
      }
    }
  }
}

.active {
  @apply text-primary-100 bg-primary-30 rounded-sm;
  // color: var(--primary-100);
}

.upload {
  @apply h-[150px];
}

.close {
  @apply cursor-pointer absolute top-[22px] right-[-40px];
}
.isEventMessage {
  @apply right-[-15px];
}
::v-deep .el-form-item{
  @apply mb-0;
}
::v-deep .el-input__count{
  @apply bottom-0 right-[20px];
}

::v-deep .el-textarea {
  @apply w-full;
}
</style>
