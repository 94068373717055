<template>
  <div class="event-marketing-setting">
    <p class="card-title">{{ displayText.blockTitle }}</p>
    <BaseElForm
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem :label="displayText.sendLimit" prop="sendLimit" required>
        <BaseElSelect
          v-model="formData.sendLimit"
          :disabled="isEdit || !!accumulation"
        >
          <BaseElSelectOption
            v-for="item in typeOfEventNumberConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
        <p class="tips">{{ sendLimitWord }}</p>
      </BaseElFormItem>

      <BaseElFormItem v-if="showControl.receiveRewardType" :label="displayText.receiveRewardType.label">
        <div>
          <div class="grid grid-cols-2" style="width: 560px; gap: 8px">
            <BaseElFormItem class="flex-1" prop="receiveRewardType">
              <ElInputWrapper>
                <BaseElSelect v-model="formData.receiveRewardType" style="width: 100%" @change="formData.maxMemberAwardCount = null">
                  <BaseElSelectOption
                    v-for="item in rewardCountAdvanceOptionsConfig"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </BaseElSelect>
              </ElInputWrapper>
            </BaseElFormItem>

            <BaseElFormItem v-if="showControl.maxMemberAwardCount" class="flex-1" prop="maxMemberAwardCount">
              <ElInputWrapper>
                <BaseElInput v-model="formData.maxMemberAwardCount" style="width: 100%" :placeholder="displayText.receiveRewardType.placeholder">
                  <template #suffix>次</template>
                </BaseElInput>
              </ElInputWrapper>
            </BaseElFormItem>
          </div>
          <p class="tips">{{ displayText.receiveRewardType.tips }}</p>
        </div>
      </BaseElFormItem>

      <BaseElFormItem v-if="showControl.singleOrderRewardType" :label="displayText.singleOrderRewardType.label">
        <div>
          <div class="grid grid-cols-2" style="width: 560px; gap: 8px">
            <BaseElFormItem class="flex-1" prop="singleOrderRwardType">
              <ElInputWrapper>
                <BaseElSelect v-model="formData.singleOrderRwardType" style="width: 100%" @change="formData.maxSingleAwardCount = null">
                  <BaseElSelectOption
                    v-for="item in singleOrderAwardCountConfig"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </BaseElSelect>
              </ElInputWrapper>
            </BaseElFormItem>

            <BaseElFormItem v-if="showControl.maxSingleAwardCount" class="flex-1" prop="maxSingleAwardCount">
              <ElInputWrapper>
                <BaseElInput v-model="formData.maxSingleAwardCount" style="width: 100%" :placeholder="displayText.singleOrderRewardType.placeholder">
                  <template #suffix>份</template>
                </BaseElInput>
              </ElInputWrapper>
            </BaseElFormItem>
          </div>
          <p class="tips">{{ displayText.singleOrderRewardType.tips }}</p>
        </div>
      </BaseElFormItem>

      <BaseElFormItem :label="displayText.eventAwardType" prop="eventAwardType" required>
        <BaseElSelect v-model="formData.eventAwardType" @change="onAwardTypeChange">
          <BaseElSelectOption
            v-for="item in featureKeysByEventAwardConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <!-- 行銷項目：堂票 -->
      <BaseElFormItem
        v-if="showControl.classTicket"
        :label="displayText.classTicket"
        prop="classTicketId"
        required
      >
        <EventClassTicketSelect :classTicketId.sync="formData.classTicketId" @change="onAwardChange" />
      </BaseElFormItem>

      <!-- 行銷項目：票券 -->
      <BaseElFormItem
        v-if="showControl.coupon"
        :label="displayText.coupon"
        prop="couponId"
        required
      >
        <EventCouponSelect :couponId.sync="formData.couponId" :couponType="couponType" @change="onAwardChange" />
      </BaseElFormItem>

      <BaseElFormItem v-if="showControl.point" :label="displayText.point.label" prop="shopPointKey">
        <BaseDataSelect
          :value.sync="formData.shopPointKey"
          :dataList="shopPointList"
          :placeholder="displayText.point.placeholder"
          objKey="key"
        />
      </BaseElFormItem>

      <BaseElFormItem prop="maxAwardStock">
        <FormItemTooltipLabel :label="displayText.maxAwardStock.label" :tooltipWidth="180">
          {{ displayText.maxAwardStock.tooltip }}
        </FormItemTooltipLabel>
        <div class="flex flex-col">
          <BaseElInput
            v-model="formData.maxAwardStock"
            :placeholder="displayText.input.placeholder"
            :disabled="formData.maxAwardStockUnlimited"
          >
            <template #suffix>{{ rewardUnitLabel[formData.eventAwardType] }}</template>
          </BaseElInput>
          <BaseElCheckbox
            v-model="formData.maxAwardStockUnlimited"
            @change="onMaxAwardStockUnlimitedChange"
          >
            {{ displayText.maxAwardStock.noLimit }}
          </BaseElCheckbox>
          <p v-if="showControl.maxAwardStockTips" class="tips">{{ displayText.maxAwardStock.tips }}</p>
        </div>
      </BaseElFormItem>

      <!-- 行銷項目： 堂票 票券 回饋金 點數 -->
      <BaseElFormItem
        v-if="showControl.eventAwardCount"
        :label="displayText.eventAwardCount"
        prop="eventAwardCount"
        required
      >
        <BaseElInput v-model="formData.eventAwardCount" :placeholder="displayText.input.placeholder">
          <template #suffix>{{ rewardUnitLabel[formData.eventAwardType] }}</template>
        </BaseElInput>
      </BaseElFormItem>

      <!-- 行銷項目：圖文訊息 -->
      <BaseElFormItem
        v-if="showControl.lineMessage"
        :label="displayText.eventMessage"
        prop="eventMessage"
        required
        class="message"
      >
        <EventMessageBlock :eventMessage.sync="formData.eventMessage" />
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  nextTick,
  computed,
  watch,
} from 'vue'
import { get, set, map } from 'lodash'
import {
  noEmptyRules,
  isDigitRules,
  integerRules,
  minRules,
} from '@/validation'
import {
  eventNumberOfReceiptsConfig,
  eventAwardConfig,
  eventTipsConfig,
  rewardCountAdvanceOptionsConfig,
  singleOrderAwardCountConfig,
} from '@/config/marketing'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import EventMessageBlock from './EventMessageBlock.vue'
import EventCouponSelect from './EventCouponSelect.vue'
import EventClassTicketSelect from './EventClassTicketSelect.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { usePermissions } from '@/use/permissions'
import { onFormRulesChangeClearValidate } from '@/use/useForm'
import store from '@/store'
import { GetEventTemplateAwardStatus } from '@/api/eventMarketing'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import BaseDataSelect from '@/components/Select/BaseDataSelect.vue'

export default defineComponent({
  name: 'EventMarketingContent',
  components: {
    EventMessageBlock,
    EventCouponSelect,
    EventClassTicketSelect,
    FormItemTooltipLabel,
    ElInputWrapper,
    BaseDataSelect,
  },
  props: [
    'FormsContext',
    'eventData',
    'sourceType',
    'accumulation',
    'eventType',
    'singleConsumption',
    'setAwardType',
  ],
  setup (props) {
    const { checkAction } = usePermissions()
    const { simpleFetch } = useFetch()
    const { shopId, shopPointList } = useShop()
    const formRef = ref(null)
    const rewardSentCount = ref(0)

    const rewardTypes = map(eventAwardConfig, 'label')
    const rewardUnitLabel = {
      classTicket: '份',
      coupon: '張',
      point: '點',
      cashback: '元',
    }

    const formData = reactive({
      sendLimit: null,
      eventAwardType: null,
      eventAwardCount: null,
      couponId: null,
      classTicketId: null,
      amountConfig: {
        amountType: null,
      },
      shopPointKey: null,
      maxAwardStock: null, // 行銷項目總數
      maxAwardStockUnlimited: true, // 行銷項目總數是否無限制

      receiveRewardType: 'none', // 總活動領獎次數進階設定
      maxMemberAwardCount: null, // 上限值
      singleOrderRwardType: 'none', // 單筆訂單領獎數量設定
      maxSingleAwardCount: null, // 單筆訂單上限值
      eventMessage: [
        {
          type: 'text',
          content: '',
          action: undefined,
          actionContent: undefined,
          Image: undefined,
        },
      ],
    })
    const displayText = computed(() => {
      const data = {
        blockTitle: '行銷內容設定',
        sendLimit: '總活動領獎次數規則',
        receiveRewardType: {
          label: '總活動領獎次數進階設定',
          placeholder: '請輸入上限數值',
          tips: '會員達成事件滿 n 次後，不會再領到獎勵 (避免單一會員領走大量獎勵)',
        },
        singleOrderRewardType: {
          label: '單筆訂單領獎數量設定',
          placeholder: '請輸入上限數值',
          tips: '會員在單筆消費累積滿 n 份後，不會再領到獎勵 (避免會員於單筆消費領走大量獎勵)',
        },
        eventAwardType: '選擇行銷項目',
        classTicket: '選擇行銷票券',
        coupon: '選擇票券',
        point: {
          label: '選擇點數',
          placeholder: '請選擇點數',
        },
        maxAwardStock: {
          label: '行銷項目總數',
          tooltip: '事件啟用後，當「行銷項目總數」不足「每次達成事件發送的行銷項目數量」，則會員達成事件條件時，不發送獎勵',
          noLimit: '無上限',
          tips: `提醒：該行銷項目先前已發出${rewardSentCount.value}${rewardUnitLabel[formData.eventAwardType]} ，請納入總數發放考量`,
        },
        eventAwardCount: '每次達成事件發送的行銷項目數量',
        eventMessage: '圖文訊息設置',
        input: {
          placeholder: '請輸入',
        }
      }
      return data
    })
    const showControl = computed(() => {
      const controls = {
        receiveRewardType: showReceiveRewardType.value,
        maxMemberAwardCount: formData.receiveRewardType === 'maxMemberAwardCount',
        singleOrderRewardType: showSingleOrderRewardType.value,
        maxSingleAwardCount: formData.singleOrderRwardType === 'maxSingleAwardCount',
        classTicket: formData.eventAwardType === 'classTicket',
        coupon: formData.eventAwardType === 'coupon',
        point: formData.eventAwardType === 'point',
        maxAwardStockTips: rewardSentCount.value > 1 && !formData.maxAwardStockUnlimited,
        eventAwardCount: formData.eventAwardType && formData.eventAwardType !== 'lineMessage',
        lineMessage: formData.eventAwardType === 'lineMessage',
      }
      return controls
    })
    const couponType = computed(() => {
      const couponTypeArray = []
      if (get(formData, 'eventAwardType') === 'coupon') {
        if (checkAction('admin.coupon.page')) couponTypeArray.push('coupon')
        if (checkAction('admin.couponExchange.page')) couponTypeArray.push('exchange')
        if (checkAction('admin.couponPospal.find')) couponTypeArray.push('pospal')
      }
      return couponTypeArray
    })

    const formRules = computed(() => {
      const rules = {
        sendLimit: [noEmptyRules('請輸入總活動領獎次數規則')],
        eventAwardType: [noEmptyRules('請輸入行銷項目')],
        StandardMessage: [noEmptyRules('請輸入圖文訊息')],
        couponId: [noEmptyRules('請選擇票券')],
        classTicketId: [noEmptyRules('請選擇堂票')],
        eventAwardCount: [
          noEmptyRules('請輸入數量'),
          isDigitRules(),
          integerRules(),
        ],
        shopPointKey: [noEmptyRules('請選擇點數')],
      }
      // 行銷項目總數
      if (!formData.maxAwardStockUnlimited) {
        rules.maxAwardStock = [
          noEmptyRules('請輸入數量'),
          isDigitRules(),
        ]
      }

      // 總活動領獎次數進階設定
      if (formData.receiveRewardType === 'maxMemberAwardCount') {
        rules.maxMemberAwardCount = [noEmptyRules(), isDigitRules(), minRules(1)]
      }
      if (formData.singleOrderRwardType === 'maxSingleAwardCount') {
        rules.maxSingleAwardCount = [noEmptyRules(), isDigitRules(), minRules(1)]
      }
      return rules
    })

    onFormRulesChangeClearValidate(formRef, formRules)

    const featureKeys = {
      classTicket: 'admin.classTicket.page',
      coupon: 'admin.coupon.page',
      point: 'admin.shopPoint.page',
      cashback: 'admin.shopCashback.page',
    }

    const featureKeysByEventAwardConfig = computed(() => {
      const marketingPermission = store.getters.computedShopMarketingPermission
      return eventAwardConfig.filter(({ value }) => {
        if (featureKeys[value]) {
          return checkAction(featureKeys[value]) && marketingPermission[value]
        }
        return true
      })
    })

    const isEdit = computed(() => {
      return !!get(props.eventData, 'id')
    })

    const typeOfEventNumberConfig = computed(() => {
      const config = ['firstOrderAmount', 'firstOrderNumber', 'registerAndLineAuth']
      if (props.sourceType === 'register') {
        return eventNumberOfReceiptsConfig.filter(
          ({ value }) => value !== 'repeat',
        )
      }

      // 過濾只顯示只領取1次
      if (config.includes(props.eventType)) {
        return eventNumberOfReceiptsConfig.filter(
          ({ value }) => value === 'once',
        )
      }
      const subscriptionEventConfig = ['singlePaymentRecord']
      if (subscriptionEventConfig.includes(props.eventType)) {
        return eventNumberOfReceiptsConfig.filter(
          ({ value }) => value === 'repeat',
        )
      }
      return eventNumberOfReceiptsConfig
    })
    const showReceiveRewardType = computed(() => {
      const subscriptionConfig = ['subscriptionPlanBenefits']
      return formData.sendLimit === 'repeat' && !subscriptionConfig.includes(props.sourceType)
    })
    const showSingleOrderRewardType = computed(() => {
      const repeatStatus = isEdit.value ? get(formData, 'amountConfig.amountType') === 'repeatRate' : props.singleConsumption === 'repeat'
      return repeatStatus
    })
    const sendLimitWord = computed(() => {
      if (props.accumulation) {
        return '規則說明：已套用上方事件範本的累積計算方式作為總活動領獎次數規則'
      }
      return props.sourceType && formData.sendLimit
        ? eventTipsConfig[props.sourceType][formData.sendLimit]
        : ''
    })

    const onMaxAwardStockUnlimitedChange = (toggle) => {
      if (toggle) formData.maxAwardStock = null
    }

    const compactData = computed(() => {
      const data = {
        sendLimit: get(formData, 'sendLimit'),
        eventAwardType: get(formData, 'eventAwardType'),
        eventAwardCount: get(formData, 'eventAwardCount'),
        maxMemberAwardCount: get(formData, 'maxMemberAwardCount'),
        maxSingleAwardCount: get(formData, 'maxSingleAwardCount'),
        maxAwardStock: get(formData, 'maxAwardStock'),
      }
      if (data.eventAwardType === 'lineMessage') {
        data.messages = get(formData, 'eventMessage')
      }
      if (data.eventAwardType === 'coupon') {
        data.relatedId = [get(formData, 'couponId')]
      }
      if (data.eventAwardType === 'classTicket') {
        data.relatedId = [get(formData, 'classTicketId')]
      }
      if (data.eventAwardType === 'point') {
        data.shopPointKey = get(formData, 'shopPointKey')
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.eventData
      const { award, amountConfig } = data.config
      set(formData, 'maxAwardStock', data.maxAwardStock)
      if (data.maxAwardStock) formData.maxAwardStockUnlimited = false
      set(formData, 'maxMemberAwardCount', award.maxMemberAwardCount)
      if (award.maxMemberAwardCount) formData.receiveRewardType = 'maxMemberAwardCount'
      set(formData, 'maxSingleAwardCount', award.maxSingleAwardCount)
      if (award.maxSingleAwardCount) formData.singleOrderRwardType = 'maxSingleAwardCount'
      if (amountConfig && amountConfig.amountType) {
        set(formData, 'amountConfig.amountType', amountConfig.amountType)
      }
      set(formData, 'sendLimit', award.sendLimit)
      set(formData, 'eventAwardType', award.type)
      set(formData, 'eventAwardCount', award.amount)
      if (award.type === 'coupon') {
        set(formData, 'couponId', award.relatedId[0])
      }
      if (award.type === 'classTicket') {
        set(formData, 'classTicketId', award.relatedId[0])
      }
      if (award.type === 'point') {
        set(formData, 'shopPointKey', award.shopPointKey)
      }
      if (award.type === 'lineMessage') {
        set(
          formData,
          'eventMessage',
          award.messages.map((item) => {
            return {
              type: 'text',
              content: '',
              action: undefined,
              actionContent: undefined,
              ...item,
            }
          }),
        )
      }
    }

    const checkEventTemplateAwardStatus = async (awardType) => {
      let awardId
      if (awardType === 'classTicket') awardId = get(formData, 'classTicketId')
      else if (awardType === 'coupon') awardId = get(formData, 'couponId')

      if (get(props.eventData, 'id')) {
        simpleFetch(GetEventTemplateAwardStatus, {
          shopId: shopId.value,
          id: get(props.eventData, 'id'),
          awardType,
          awardId: awardId || null,
        }, (res) => {
          rewardSentCount.value = res.sentCount
        })
      }
    }

    onMounted(async () => {
      await nextTick()

      if (get(props.eventData, 'id')) syncData()
      props.FormsContext.setFormRef('content', formRef.value)

      const awardType = get(props.eventData, 'config.award.type')

      checkEventTemplateAwardStatus(awardType)

      // let awardId
      // if (awardType === 'classTicket') awardId = get(formData, 'classTicketId')
      // else if (awardType === 'coupon') awardId = get(formData, 'couponId')
      // if (get(props.eventData, 'id')) {
      //   simpleFetch(GetEventTemplateAwardStatus, {
      //     shopId: shopId.value,
      //     id: get(props.eventData, 'id'),
      //     awardType: get(props.eventData, 'config.award.type'),
      //     awardId: awardId || null,
      //   }, (res) => {
      //     rewardSentCount.value = res.sentCount
      //   })
      // }
    })

    watch(formData, () => {
      props.FormsContext.setFormData('content', { ...compactData.value })
    })

    const onAwardTypeChange = (awardType) => {
      formData.couponId = null
      formData.classTicketId = null
      props.setAwardType(awardType)
    }
    const onAwardChange = (awardType) => {
      checkEventTemplateAwardStatus(formData.eventAwardType)
    }

    const trans = {
      rate: 'repeat',
      once: 'once',
    }

    watch(
      () => props.accumulation,
      (newValue) => {
        formData.sendLimit = trans[newValue]
      },
    )
    watch(
      () => props.eventType,
      (newValue) => {
        formData.sendLimit = trans[props.accumulation] || null
      },
    )

    return {
      formRef,
      formData,
      formRules,
      eventNumberOfReceiptsConfig,
      featureKeysByEventAwardConfig,
      onAwardTypeChange,
      onAwardChange,
      sendLimitWord,
      isEdit,
      typeOfEventNumberConfig,
      couponType,
      rewardTypes,
      rewardCountAdvanceOptionsConfig,
      singleOrderAwardCountConfig,
      onMaxAwardStockUnlimitedChange,
      rewardUnitLabel,
      rewardSentCount,
      shopPointList,
      compactData,
      displayText,
      showControl,
    }
  },
})
</script>

<style lang="postcss" scoped>
.message ::v-deep .el-input {
  @apply w-full;
}
.tips {
  @apply text-[#636363];
}
::v-deep .el-form-item__content .header {
  @apply leading-normal;
}
</style>
