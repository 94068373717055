<template>
  <div class="event-message-block">
    <MessageBlock
      v-for="(block, index) in messages"
      :key="`block-${index}`"
      class="mb-3"
      ref="messageBlock"
      :model.sync="messages[index]"
      :closeBtn="allowEmpty || messages.length > 1"
      :hideAction="hideAction"
      maxContent="500"
      @error="onMessageError = true"
      @checked="onMessageError = false"
      @close="removeNotifyMessage(index)"
      :isEventMessage="true"
    />
    <AddButton v-if="showControl.addBtn" @click="addNotifyMessage" />
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { get } from 'lodash'
import MessageBlock from '@/components/Message/MessageBlock.vue'

export default defineComponent({
  name: 'EventMessageBlock',
  components: { MessageBlock },
  // props: ['eventMessage'],
  props: {
    eventMessage: {
      type: Array,
      default: () => [],
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    hideAction: {
      type: Boolean,
      default: false,
    },
    maxCount: {
      type: Number,
      default: 9999,
    },
  },
  emits: ['update:eventMessage'],
  setup (props, { emit }) {
    const onMessageError = ref(false)
    const messageBlock = ref(null)
    const messages = computed({
      get: () => props.eventMessage,
      set: (newValue) => emit('update:eventMessage', newValue),
    })
    const showControl = computed(() => {
      const controls = {
        addBtn: get(messages.value, 'length') < props.maxCount,
      }
      return controls
    })
    const addNotifyMessage = () => {
      messages.value.push({
        type: 'text',
        content: '',
        action: undefined,
        actionContent: undefined,
        Image: undefined,
      })
    }

    const removeNotifyMessage = (index) => {
      messages.value.splice(index, 1)
    }
    return {
      messages,
      addNotifyMessage,
      removeNotifyMessage,
      onMessageError,
      showControl,
      messageBlock,
    }
  },
})
</script>
