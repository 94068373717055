<template>
  <div class="event-marketing-setting">
    <p class="card-title">{{ displayText.blockTitle }}</p>
    <p class="text-[12px] text-[#636363] mb-[20px]">
      {{ displayText.subTitle }}
    </p>
    <BaseElForm
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <!-- 行銷項目：圖文訊息 -->
      <BaseElFormItem
        class="message"
      >
        <EventMessageBlock
          ref="eventMessages"
          :eventMessage.sync="formData.messages"
          :maxCount="3"
          allowEmpty
          hideAction
        />
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  nextTick,
  computed,
  watch,
} from 'vue'
import { get, set } from 'lodash'
import EventMessageBlock from './EventMessageBlock.vue'
import { onFormRulesChangeClearValidate } from '@/use/useForm'

export default defineComponent({
  name: 'EventMarketingContent',
  components: {
    EventMessageBlock,
  },
  props: [
    'FormsContext',
    'eventData',
    'sourceType',
    'accumulation',
    'eventType',
    'singleConsumption',
  ],
  setup (props) {
    const formRef = ref(null)
    const eventMessages = ref(null)
    const formData = reactive({
      messages: [],
    })
    const displayText = computed(() => {
      const data = {
        blockTitle: '圖文訊息設定',
        subTitle: '於行銷項目發放後，搭配圖文訊息',
      }
      return data
    })
    const formRules = computed(() => {
      const rules = {}
      return rules
    })

    onFormRulesChangeClearValidate(formRef, formRules)

    const compactData = computed(() => {
      const data = {
        extraLineMessages: get(formData, 'messages'),
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.eventData
      const { award } = data.config
      set(
        formData,
        'messages',
        award.extraLineMessages.map((item) => {
          return {
            type: 'text',
            content: '',
            action: undefined,
            actionContent: undefined,
            ...item,
          }
        }),
      )
    }

    onMounted(async () => {
      await nextTick()

      if (get(props.eventData, 'id')) syncData()
      props.FormsContext.setFormRef('extraLineMessages', formRef.value)
    })

    watch(formData, () => {
      props.FormsContext.setFormData('extraLineMessages', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
      compactData,
      displayText,
      eventMessages,
    }
  },
})
</script>

<style lang="postcss" scoped>
.card-title {
  @apply mb-[6px];
}
.message ::v-deep .el-input {
  @apply w-full;
}
.tips {
  @apply text-[#636363];
}
::v-deep .el-form-item__content .header {
  @apply leading-normal;
}
</style>
