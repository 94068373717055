<template>
  <el-dialog
    :title="displayText.title"
    :visible="true"
    width="700px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <header class="flex" style="gap: 16px">
      <BaseElInput v-model="search.name" :placeholder="displayText.name.placeholder" clearable>
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
        />
      </BaseElInput>
      <BaseElSelect v-model="search.type" :placeholder="displayText.type.placeholder" clearable>
        <BaseElSelectOption v-for="option in typeOptions" :key="option.value" :label="option.label" :value="option.value" />
      </BaseElSelect>
    </header>

    <main>
      <div class="grid-container text-primary-100" style="padding-top: 21px; padding-bottom: 16px">
        <BaseElCheckbox :label="displayText.table.label" :disabled="!multiple" @change="selectAll">
          <p class="text-primary-100">{{ displayText.table.name }}</p>
        </BaseElCheckbox>
        <p class="text-center">{{ displayText.table.type }}</p>
        <p class="text-center">{{ displayText.table.exp }}</p>
      </div>

      <BaseElCheckboxGroup v-model="selected">
        <div v-for="product in displayData[tableOptions.page - 1]" :key="product.id">
          <hr>
          <div class="grid-container" style="padding: 16px 0;">
            <BaseElCheckbox :label="product.id" :disabled="disabledItem(product)">
              <div class="inline-flex items-center relative" style="gap: 4px; bottom: -2px">
                <span class="truncate">{{ product.name }}</span>
                <TipInfo v-if="disabledReason(product)" :size="20">
                  {{ disabledReason(product) }}
                </TipInfo>
              </div>
            </BaseElCheckbox>
            <p class="text-center">
              {{ get(couponTypeConfig, `${product.type}.label`) }}
            </p>
            <p class="text-center">
              {{ formatExpPreviewText({
                isExp: product.isExp,
                expType: product.expType,
                expDay: product.expDay,
                endOfPeriodUnit: product.endOfPeriodUnit,
                expAt: product.expAt,
              }) }}
            </p>
          </div>
        </div>
      </BaseElCheckboxGroup>
    </main>

    <div>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="flattenDeep(displayData).length"
      />
    </div>

    <div class="flex justify-end items-center" style="padding-top: 26px">
      <BaseElButton plain @click="$emit('close')">{{ displayText.button.cancel }}</BaseElButton>
      <BaseElButton type="primary" :disabled="!selected.length" @click="onSubmit">{{ displayText.button.confirm }}</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { map, chunk, get, flattenDeep } from 'lodash'
import { useTable } from '@/use/table'
import { useCoupon } from '@/use/useCoupon'
import { couponTypeConfig } from '@/config/couponExchange'
import dayjs from '@/lib/dayjs'
import TipInfo from '../TipInfo.vue'
import { i18n } from '@/plugins/i18n/i18n'
import { couponInvalidReasonConfig } from '@/config/coupon'

export default defineComponent({
  name: 'CouponAdvanceSelect',
  components: { TipInfo },
  props: {
    typeOptions: {
      type: Object,
      default: () => ({ none: { label: '暫無資料', value: 'none' } }),
    },
    data: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false },
    max: { type: [Number, null], default: null },
    disabledExp: Boolean,
  },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { formatExpPreviewText } = useCoupon()
    const { tableData, tableDataCount, tableOptions } = useTable()
    tableOptions.pageLimit = 8
    const displayData = computed(() => {
      let data = [...props.data]
      if (search.name) data = data.filter(i => i.name.includes(search.name))
      if (search.type) data = data.filter(i => i.type === search.type)
      return chunk(data, tableOptions.pageLimit)
    })
    const displayText = computed(() => {
      const data = {
        title: '選擇票券',
        name: {
          placeholder: '請輸入名稱',
        },
        type: {
          placeholder: '請選擇類型',
        },
        table: {
          label: '產品名稱',
          name: '名稱',
          type: '類型',
          exp: '有效期限',
        },
        button: {
          cancel: i18n.t('common.button.cancel.text'),
          confirm: i18n.t('common.button.confirm.text'),
        },
      }
      return data
    })
    const isOpentixDisabled = (product) => {
      if (product.type !== 'opentix') return false
      return get(product.extraStatus, 'opentixIsDisabled')
    }

    const disabledItem = computed(() => {
      return (product) => {
        const disabled = disabledProduct(product.id) ||
        checkExp(product) ||
        product.isRemove ||
        product.isInvalid ||
        isOpentixDisabled(product)

        return disabled
      }
    })
    const disabledProduct = (productId) => {
      let disabled = false
      if (props.max) {
        if (selected.value.length === props.max) {
          if (selected.value.includes(productId)) return false
          return true
        }
      }
      if (props.multiple) return false
      if (!selected.value.length) return false
      if (selected.value.includes(productId)) disabled = false
      else disabled = true
      return disabled
    }
    const search = reactive({
      name: null,
      type: null,
    })
    const selected = ref([])

    const selectAll = (all) => {
      if (all) {
        selected.value = map(displayData.value[tableOptions.page - 1], 'id')
        if (props.max) selected.value = selected.value.slice(0, props.max)
      } else selected.value = []
    }

    const checkExp = (product) => {
      if (!props.disabledExp) return false
      const expType = get(product, 'expType')
      if (expType === 'expDay') return false
      const expDate = get(product, 'expAt')
      if (!expDate) return false
      const isExp = dayjs(expDate).isSameOrBefore(dayjs())
      return isExp
    }

    const onSubmit = () => {
      emit('confirm', selected.value)
      emit('close')
    }

    const disabledReason = (item) => {
      if (checkExp(item)) return '已過期'
      else if (item.isRemove) return '該品項已被刪除'
      else if (isOpentixDisabled(item)) return 'opentix 該品項已被停用'
      else if (item.isInvalid) {
        return get(couponInvalidReasonConfig, `${item.invalidReasonCode}.label`)
      }
    }

    onMounted(() => {
      tableData.value = props.data
      tableDataCount.value = props.data.length
    })

    return {
      get,
      flattenDeep,
      onSubmit,
      search,
      selected,
      selectAll,
      displayData,
      tableDataCount,
      tableOptions,
      disabledProduct,
      dayjs,
      checkExp,
      disabledItem,
      formatExpPreviewText,
      couponTypeConfig,
      displayText,
      disabledReason,
    }
  },
})
</script>

<style scoped lang="postcss">
.grid-container {
  @apply grid gap-[20px];
  @apply text-normal;
  grid-template-columns: 1fr 160px 260px;
}
::v-deep .el-input {
  @apply w-full;
}
::v-deep .el-select {
  @apply w-full;
}
::v-deep .el-checkbox {
  @apply w-[180px];
}
::v-deep .el-checkbox .el-checkbox__label {
  @apply truncate w-full;
}
</style>
