<template>
  <div class="event-marketing-edit">
    <PageTitle
      :title="displayText.pageTitle"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 22px"
      @iconClick="$router.push({ name: 'EventMarketingList' })"
    />
    <div v-loading="loading" />
    <section v-if="!loading" class="content-warp">
      <EventMarketingInfo
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :eventData="eventData"
      />
      <EventMarketingSetting
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :eventData="eventData"
        :setSourceType="setSourceType"
        :setAccumulation="setAccumulation"
        :setEventType="setEventType"
        :setSingleConsumption="setSingleConsumption"
      />
      <EventMarketingAdvancedSetting
        v-show="showControl.advanceSetting"
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :eventData="eventData"
        :sourceType="sourceType"
      />
      <EventMarketingContent
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :eventData="eventData"
        :sourceType="sourceType"
        :accumulation="accumulation"
        :eventType="eventType"
        :singleConsumption="singleConsumption"
        :setAwardType="setAwardType"
      />
      <EventMarketingEventMessageSetting
        ref="eventMessageSettingRef"
        v-show="showControl.eventMessageSetting"
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :eventData="eventData"
        :sourceType="sourceType"
        :accumulation="accumulation"
        :eventType="eventType"
        :singleConsumption="singleConsumption"
      />
      <EventMarketingTimeSetting
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :eventData="eventData"
        :sourceType="sourceType"
      />
    </section>

    <WarningDialog
      v-if="modal.memberExternalTransactionEventWarn"
      width="650px"
      :btnString="displayText.btn.comfirm"
      :cancelBtnString="displayText.btn.cancel"
      @close="modal.memberExternalTransactionEventWarn = false"
      @confirm="onSubmit"
    >
      <template slot="body">
        <div class="flex flex-col" style="gap: 20px">
          <p v-html="displayText.dialog.title" />
          <p v-html="displayText.dialog.content1" />
          <p v-html="displayText.dialog.content2" />
        </div>
      </template>
    </WarningDialog>

    <PageFixedFooter @cancel="onCancel" @confirm="submitCheck" />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import PageTitle from '@/components/Title/PageTitle.vue'
import EventMarketingInfo from './components/EventMarketingInfo.vue'
import EventMarketingSetting from './components/EventMarketingSetting.vue'
import EventMarketingAdvancedSetting from './components/EventMarketingAdvancedSetting.vue'
import EventMarketingContent from './components/EventMarketingContent.vue'
import EventMarketingEventMessageSetting from './components/EventMarketingEventMessageSetting.vue'
import EventMarketingTimeSetting from './components/EventMarketingTimeSetting.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import FormsContext from './formsContext'
import {
  CreateEventMarketing,
  FindEventMarketing,
  UpdateEventMarketing,
} from '@/api/eventMarketing'
import store from '@/store'
import { get, includes } from 'lodash'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'EventMarketingEdit',
  components: {
    PageTitle,
    EventMarketingInfo,
    EventMarketingSetting,
    EventMarketingAdvancedSetting,
    EventMarketingContent,
    EventMarketingEventMessageSetting,
    EventMarketingTimeSetting,
    WarningDialog,
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const shopId = computed(() => store.getters.shop)
    const eventId = computed(() => route.params.id)
    const loading = ref(true)
    const eventData = ref(null)
    const eventMessageSettingRef = ref(null)
    const sourceType = ref(null)

    const eventType = ref(null)
    const eventAwardType = ref(null)
    const accumulation = ref(null)
    const singleConsumption = ref(null)

    const modal = reactive({
      memberExternalTransactionEventWarn: false,
    })

    const setSourceType = (type) => {
      sourceType.value = type
    }

    const setEventType = (type) => {
      eventType.value = type
    }
    const setAccumulation = (type) => {
      accumulation.value = type
    }
    const setSingleConsumption = (type) => {
      singleConsumption.value = type
    }
    const setAwardType = (type) => {
      eventAwardType.value = type
    }
    const displayText = computed(() => {
      const data = {
        pageTitle: '新增事件行銷',
        dialog: {
          title: '請確認已了解各渠道POS相關規範與認列時間！',
          content1: `（ㄧ）特殊狀況提醒：<br>
            若會員於活動期間最後一天消費，剛好遇到跨日認列訂單問題，將影響該訂單無法計算！<br>
            故建議活動截止日調整為隔日凌晨6:00後，以確保會員權益。<br>
            <span class="text-danger">舉例：原活動期間截止日是10/31 23:59，可調整截止時間為11/1 06:00</span>`,
          content2: `（二）瑞乘滿件訂單提醒<br>
            請再次留意結帳流程，本系統將不認列「次卡支付訂單」。<br>
            若有設定「滿件」事件行銷活動，建議將該消費品項獨立結帳，以確保會員權益。`,
        },
        btn: {
          comfirm: '已了解',
          cancel: '返回修改',
        }
      }
      return data
    })
    const showControl = computed(() => {
      const awardTypeList = ['classTicket', 'coupon', 'point', 'cashback']
      const sourceTypeList = [
        'memberReferralRecordSuccessReferrer',
        'memberReferralRecordSuccessReferee',
        'subscriptionPlanBenefits',
      ]
      const awardType = eventAwardType.value || get(eventData.value, 'config.award.type')
      const controls = {
        eventMessageSetting: includes(awardTypeList, awardType),
        advanceSetting: !includes(sourceTypeList, sourceType.value),
      }
      return controls
    })

    const findEventMarketing = async () => {
      loading.value = true
      const [res, err] = await FindEventMarketing({
        shopId: shopId.value,
        id: eventId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      if (res.type === 'orderSumNumber' && res.source === 'memberReferralRecordSuccessReferrer') {
        res.type = 'referralPeopleSum'
      }

      eventData.value = res
    }

    const createEventMarketing = async (payload) => {
      loading.value = true
      const [, err] = await CreateEventMarketing({
        shopId: shopId.value,
        postBody: {
          ...payload,
        },
      })
      if (err) throw err
      window.$message.success('新增成功!')
    }

    const updateEventMarketing = async (payload) => {
      loading.value = true
      const [, err] = await UpdateEventMarketing({
        shopId: shopId.value,
        id: eventId.value,
        postBody: {
          ...payload,
        },
      })
      if (err) throw err
      window.$message.success('更新成功！')
    }

    const onCancel = () => {
      router.push({
        name: 'EventMarketingList',
      })
    }

    const checkCouponExp = () => {
      const relateData = get(FormsContext.tempData, 'relateData')
      const specifyExpiredDate = get(relateData, 'specifyExpiredDate') || get(relateData, 'expAt')
      if (!specifyExpiredDate) return true
      const isExp = dayjs(specifyExpiredDate).isBefore(dayjs())
      if (isExp) {
        window.$message.warning('行銷票券已過期，請重新選擇其他票券 !')
        return false
      }
      return true
    }

    const submitCheck = async () => {
      const allPass = await FormsContext.validateAll()
      let checkEventMessage = true
      const eventMessage = get(eventMessageSettingRef.value, 'eventMessages.messageBlock')
      if (eventMessage) {
        for (const message of eventMessage) {
          checkEventMessage = await message.checkEventMessage()
        }
      }
      if (allPass && checkEventMessage) {
        if (!checkCouponExp()) return
        const data = FormsContext.composFormData()
        if (get(data, 'source') === 'memberExternalTransactionCompleted') {
          modal.memberExternalTransactionEventWarn = true
        } else onSubmit()
      }
    }

    const exceptionHandle = (payload) => {
      if (payload.type === 'referralPeopleSum') payload.type = 'orderSumNumber'
      return payload
    }

    const onSubmit = async () => {
      const allPass = await FormsContext.validateAll()
      if (allPass) {
        if (!checkCouponExp()) return
        const data = FormsContext.composFormData()
        const {
          name,
          startAt,
          endAt,
          source,
          type,
          sendLimit,
          eventAwardType,
          eventAwardCount,
          sendDateSetting,
          specifySendDay,
          specifySendTime,
          messages,
          extraLineMessages,
          relatedId,
          filters,
          amountConfig,
          sumUp,
          spec,
          maxMemberAwardCount,
          maxSingleAwardCount,
          maxAwardStock,
          multiSources,
          multiSpecs,
          subscriptionConfig,
          shopPointKey,
        } = data
        const postBody = exceptionHandle({
          name,
          startAt,
          endAt,
          source,
          type,
          config: {
            filters,
            amountConfig,
            sumUp,
            spec,
            award: {
              sendLimit,
              type: eventAwardType,
              amount: eventAwardCount || 0,
              relatedId: relatedId || [],
              sendDateSetting,
              specifySendDay: specifySendDay || null,
              specifySendTime: specifySendTime || null,
              messages: messages || [],
              extraLineMessages: extraLineMessages || [],
              includeLineMessage: extraLineMessages ? extraLineMessages.length > 0 : false,
              maxMemberAwardCount,
              maxSingleAwardCount,
              shopPointKey,
            },
            multiSources,
            multiSpecs,
            subscriptionConfig,
          },
          maxAwardStock,
        })
        try {
          if (!eventId.value) await createEventMarketing(postBody)
          if (eventId.value) await updateEventMarketing(postBody)
          if (route.query.back) {
            router.push({ name: 'MemberInviteSetting' })
          } else {
            router.push({ name: 'EventMarketingList' })
          }
        } catch (error) {
          window.$message.error(error)
          loading.value = false
        }
      }
    }

    onMounted(async () => {
      if (eventId.value) await findEventMarketing()
      else loading.value = false
    })

    return {
      loading,
      onSubmit,
      onCancel,
      FormsContext,
      eventData,
      sourceType,
      setSourceType,
      accumulation,
      setAccumulation,
      eventType,
      setEventType,
      modal,
      submitCheck,
      setSingleConsumption,
      singleConsumption,
      showControl,
      setAwardType,
      displayText,
      eventMessageSettingRef,
    }
  },
})
</script>

<style lang="postcss" scoped>
.content-warp {
  @apply flex flex-col gap-5;
}

.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
