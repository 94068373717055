<template>
  <BaseElSelect
    v-model="syncModel"
    filterable
    remote
    :multiple="multiple"
    clearable
    v-bind="$attrs"
    reserve-keyword
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :value-key="valueKey"
    style="height: 100%;"
    @change="$emit('change', $event)"
    @clear="$emit('clear', $event)"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <BaseElSelectOption
      v-for="(item, index) in dataList"
      :key="`${index} - ${item.name}`"
      :label="item.name"
      :value="getValue(item)"
    />
  </BaseElSelect>
</template>
<script>
import { useVModel } from '@vueuse/core'

export default {
  name: 'BaseDataSelect',
  props: {
    value: [String, Number, Array, Object],
    objKey: String,
    multiple: Boolean,
    valueKey: {
      type: String,
      default: 'id',
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const syncModel = useVModel(props, 'value', emit)

    return { syncModel }
  },

  methods: {
    getValue (item) {
      if (this.objKey) return item[this.objKey]
      else return item
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-select  {
  height: 100% !important;
}
::v-deep .el-input  {
  height: 100% !important;
}

::v-deep .el-select__tags {
@apply flex-nowrap;
}

::v-deep .el-select__tags .el-tag {
  @apply max-w-[120px];
}
</style>
